var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Card",
        [
          _c(
            "Layout",
            [
              _c(
                "Sider",
                {
                  staticStyle: {
                    background: "#fff",
                    "max-width": "220px",
                    flex: "0 0 220px",
                  },
                  attrs: { "hide-trigger": "" },
                },
                [
                  _c(
                    "Menus",
                    {
                      attrs: {
                        "active-name": _vm.currName,
                        theme: "light",
                        width: "auto",
                      },
                      on: {
                        "on-select": function ($event) {
                          _vm.currName = $event
                        },
                      },
                    },
                    [
                      _c("MenuItem", { attrs: { name: "oss" } }, [
                        _vm._v("文件对象存储配置"),
                      ]),
                      _c("MenuItem", { attrs: { name: "sms" } }, [
                        _vm._v("短信配置"),
                      ]),
                      _c("MenuItem", { attrs: { name: "email" } }, [
                        _vm._v("邮件配置"),
                      ]),
                      _c("MenuItem", { attrs: { name: "vaptcha" } }, [
                        _vm._v("Vaptcha验证码"),
                      ]),
                      _c("MenuItem", { attrs: { name: "stopWord" } }, [
                        _vm._v("禁用词管理"),
                      ]),
                      _c("MenuItem", { attrs: { name: "notice" } }, [
                        _vm._v("系统公告配置"),
                      ]),
                      _c("MenuItem", { attrs: { name: "other" } }, [
                        _vm._v("其他配置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Content",
                {
                  style: {
                    padding: "8px 40px",
                    minHeight: "550px",
                    background: "#fff",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "oss",
                          expression: "currName == 'oss'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "setting-title" }, [
                        _vm._v("文件对象存储配置"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "Form",
                            {
                              ref: "ossForm",
                              attrs: {
                                model: _vm.oss,
                                "label-width": 110,
                                "label-position": "left",
                                rules: _vm.ossValidate,
                              },
                            },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "服务提供商",
                                    prop: "serviceName",
                                  },
                                },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "230px" },
                                      attrs: { placeholder: "请选择" },
                                      on: { "on-change": _vm.changeOss },
                                      model: {
                                        value: _vm.oss.serviceName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.oss, "serviceName", $$v)
                                        },
                                        expression: "oss.serviceName",
                                      },
                                    },
                                    [
                                      _c(
                                        "Option",
                                        { attrs: { value: "LOCAL_OSS" } },
                                        [_vm._v("本地服务器")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "MINIO_OSS" } },
                                        [_vm._v("MinIO")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "QINIU_OSS" } },
                                        [_vm._v("七牛云")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "ALI_OSS" } },
                                        [_vm._v("阿里云")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "TENCENT_OSS" } },
                                        [_vm._v("腾讯云")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.oss.serviceName != "LOCAL_OSS"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "accessKey",
                                            prop: "accessKey",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticStyle: { width: "380px" },
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入accessKey",
                                              disabled: _vm.changeLoading,
                                            },
                                            model: {
                                              value: _vm.oss.accessKey,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oss,
                                                  "accessKey",
                                                  $$v
                                                )
                                              },
                                              expression: "oss.accessKey",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "secretKey",
                                            prop: "secretKey",
                                          },
                                        },
                                        [
                                          _c(
                                            "Tooltip",
                                            {
                                              attrs: {
                                                placement: "right",
                                                content:
                                                  "无法编辑时请先点击查看图标",
                                              },
                                            },
                                            [
                                              _c("Input", {
                                                staticClass: "input-see",
                                                staticStyle: { width: "380px" },
                                                attrs: {
                                                  type: "text",
                                                  disabled: _vm.changeLoading,
                                                  readonly: !_vm.changedOssSK,
                                                  icon: "ios-eye",
                                                  placeholder:
                                                    "请输入secretKey",
                                                },
                                                on: {
                                                  "on-click": function (
                                                    $event
                                                  ) {
                                                    return _vm.seeSecret(
                                                      _vm.oss.serviceName
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.oss.secretKey,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oss,
                                                      "secretKey",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "oss.secretKey",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.oss.serviceName == "QINIU_OSS"
                                        ? _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "zone存储区域",
                                                prop: "zone",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticStyle: {
                                                    width: "380px",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择存储区域",
                                                    disabled: _vm.changeLoading,
                                                  },
                                                  model: {
                                                    value: _vm.oss.zone,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.oss,
                                                        "zone",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "oss.zone",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Option",
                                                    { attrs: { value: -1 } },
                                                    [_vm._v("自动判断")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    { attrs: { value: 0 } },
                                                    [_vm._v("华东")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    { attrs: { value: 1 } },
                                                    [_vm._v("华北")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    { attrs: { value: 2 } },
                                                    [_vm._v("华南")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    { attrs: { value: 3 } },
                                                    [_vm._v("北美")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    { attrs: { value: 4 } },
                                                    [_vm._v("东南亚")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "bucket空间",
                                            prop: "bucket",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticStyle: { width: "380px" },
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入bucket空间名",
                                              disabled: _vm.changeLoading,
                                            },
                                            model: {
                                              value: _vm.oss.bucket,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.oss, "bucket", $$v)
                                              },
                                              expression: "oss.bucket",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.oss.serviceName == "TENCENT_OSS"
                                        ? _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "所属地域",
                                                prop: "bucketRegion",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticStyle: {
                                                    width: "380px",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择或输入搜索",
                                                    filterable: "",
                                                    transfer: "",
                                                    disabled: _vm.changeLoading,
                                                  },
                                                  model: {
                                                    value: _vm.oss.bucketRegion,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.oss,
                                                        "bucketRegion",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "oss.bucketRegion",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.dictBucketRegions,
                                                  function (item, i) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "endpoint域名",
                                            prop: "endpoint",
                                            error: _vm.errorMsg,
                                          },
                                        },
                                        [
                                          _c(
                                            "Input",
                                            {
                                              staticStyle: { width: "380px" },
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.endpointPH,
                                                disabled: _vm.changeLoading,
                                              },
                                              model: {
                                                value: _vm.oss.endpoint,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.oss,
                                                    "endpoint",
                                                    $$v
                                                  )
                                                },
                                                expression: "oss.endpoint",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticStyle: {
                                                    width: "80px",
                                                  },
                                                  attrs: {
                                                    slot: "prepend",
                                                    prop: "http",
                                                  },
                                                  slot: "prepend",
                                                  model: {
                                                    value: _vm.oss.http,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.oss,
                                                        "http",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "oss.http",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: {
                                                        value: "http://",
                                                      },
                                                    },
                                                    [_vm._v("http://")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: {
                                                        value: "https://",
                                                      },
                                                    },
                                                    [_vm._v("https://")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "存储磁盘路径",
                                            prop: "filePath",
                                            error: _vm.errorMsg2,
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            staticStyle: { width: "380px" },
                                            attrs: {
                                              type: "text",
                                              placeholder: "例如D:/upload",
                                              disabled: _vm.changeLoading,
                                            },
                                            model: {
                                              value: _vm.oss.filePath,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oss,
                                                  "filePath",
                                                  $$v
                                                )
                                              },
                                              expression: "oss.filePath",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "文件预览接口",
                                            prop: "endpoint",
                                            error: _vm.errorMsg,
                                          },
                                        },
                                        [
                                          _c(
                                            "Input",
                                            {
                                              staticStyle: { width: "380px" },
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "请输入文件预览接口",
                                              },
                                              model: {
                                                value: _vm.oss.endpoint,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.oss,
                                                    "endpoint",
                                                    $$v
                                                  )
                                                },
                                                expression: "oss.endpoint",
                                              },
                                            },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  staticStyle: {
                                                    width: "80px",
                                                  },
                                                  attrs: {
                                                    slot: "prepend",
                                                    prop: "http",
                                                    disabled: _vm.changeLoading,
                                                  },
                                                  slot: "prepend",
                                                  model: {
                                                    value: _vm.oss.http,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.oss,
                                                        "http",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "oss.http",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: {
                                                        value: "http://",
                                                      },
                                                    },
                                                    [_vm._v("http://")]
                                                  ),
                                                  _c(
                                                    "Option",
                                                    {
                                                      attrs: {
                                                        value: "https://",
                                                      },
                                                    },
                                                    [_vm._v("https://")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                              _c(
                                "FormItem",
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.changeLoading,
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.saveEditOss },
                                    },
                                    [_vm._v("保存并启用 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.loading
                            ? _c("Spin", { attrs: { fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "sms",
                          expression: "currName == 'sms'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "setting-title" }, [
                        _vm._v("短信配置"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "Form",
                            {
                              ref: "smsForm",
                              attrs: {
                                model: _vm.sms,
                                "label-width": 110,
                                "label-position": "left",
                                rules: _vm.smsValidate,
                              },
                            },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "服务提供商",
                                    prop: "serviceName",
                                  },
                                },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "230px" },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.sms.serviceName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sms, "serviceName", $$v)
                                        },
                                        expression: "sms.serviceName",
                                      },
                                    },
                                    [
                                      _c(
                                        "Option",
                                        { attrs: { value: "ALI_SMS" } },
                                        [_vm._v("阿里云")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "accessKey",
                                    prop: "accessKey",
                                  },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.sms.accessKey,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sms, "accessKey", $$v)
                                      },
                                      expression: "sms.accessKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "secretKey",
                                    prop: "secretKey",
                                  },
                                },
                                [
                                  _c(
                                    "Tooltip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        content: "无法编辑时请先点击查看图标",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        staticClass: "input-see",
                                        staticStyle: { width: "380px" },
                                        attrs: {
                                          type: "text",
                                          readonly: !_vm.changedSmsSK,
                                          icon: "ios-eye",
                                          placeholder: "请输入",
                                        },
                                        on: {
                                          "on-click": function ($event) {
                                            return _vm.seeSecret(
                                              _vm.sms.serviceName
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.sms.secretKey,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.sms, "secretKey", $$v)
                                          },
                                          expression: "sms.secretKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "短信签名",
                                    prop: "signName",
                                  },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入短信签名，例如XBoot",
                                    },
                                    model: {
                                      value: _vm.sms.signName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sms, "signName", $$v)
                                      },
                                      expression: "sms.signName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                { attrs: { label: "使用场景", prop: "type" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "380px" },
                                      attrs: { placeholder: "请选择" },
                                      on: { "on-change": _vm.changeSmsType },
                                      model: {
                                        value: _vm.sms.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sms, "type", $$v)
                                        },
                                        expression: "sms.type",
                                      },
                                    },
                                    [
                                      _c("Option", { attrs: { value: "0" } }, [
                                        _vm._v("通用验证码"),
                                      ]),
                                      _c("Option", { attrs: { value: "1" } }, [
                                        _vm._v("注册"),
                                      ]),
                                      _c("Option", { attrs: { value: "2" } }, [
                                        _vm._v("登录"),
                                      ]),
                                      _c("Option", { attrs: { value: "3" } }, [
                                        _vm._v("修改绑定手机号"),
                                      ]),
                                      _c("Option", { attrs: { value: "4" } }, [
                                        _vm._v("修改密码"),
                                      ]),
                                      _c("Option", { attrs: { value: "5" } }, [
                                        _vm._v("重置密码"),
                                      ]),
                                      _c("Option", { attrs: { value: "6" } }, [
                                        _vm._v("工作流消息"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "模版CODE",
                                    prop: "templateCode",
                                  },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "请输入场景对应短信模版CODE，例如SMS_123456789",
                                    },
                                    model: {
                                      value: _vm.sms.templateCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sms, "templateCode", $$v)
                                      },
                                      expression: "sms.templateCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.saveEditSms },
                                    },
                                    [_vm._v("保存更改 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.loading
                            ? _c("Spin", { attrs: { fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "email",
                          expression: "currName == 'email'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "setting-title" }, [
                        _vm._v("邮箱配置"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "Form",
                            {
                              ref: "emailForm",
                              attrs: {
                                model: _vm.email,
                                "label-width": 110,
                                "label-position": "left",
                                rules: _vm.emailValidate,
                              },
                            },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: { label: "邮箱服务器", prop: "host" },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "请输入邮箱服务器Host，例如QQ邮箱为smtp.qq.com",
                                    },
                                    model: {
                                      value: _vm.email.host,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.email, "host", $$v)
                                      },
                                      expression: "email.host",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "发送邮箱账号",
                                    prop: "username",
                                  },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入发送者Email账号",
                                    },
                                    model: {
                                      value: _vm.email.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.email, "username", $$v)
                                      },
                                      expression: "email.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "邮箱授权码",
                                    prop: "password",
                                  },
                                },
                                [
                                  _c(
                                    "Tooltip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        content: "无法编辑时请先点击查看图标",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        staticClass: "input-see",
                                        staticStyle: { width: "380px" },
                                        attrs: {
                                          type: "text",
                                          readonly: !_vm.changedEmailPass,
                                          icon: "ios-eye",
                                          placeholder:
                                            "请输入邮箱授权码，进入邮箱-设置中可找到",
                                        },
                                        on: {
                                          "on-click": function ($event) {
                                            return _vm.seeSecret(
                                              "EMAIL_SETTING"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.email.password,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.email, "password", $$v)
                                          },
                                          expression: "email.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.saveEditEmail },
                                    },
                                    [_vm._v("保存更改 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.loading
                            ? _c("Spin", { attrs: { fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "vaptcha",
                          expression: "currName == 'vaptcha'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "setting-title" }, [
                        _vm._v("Vaptcha验证码配置"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "Form",
                            {
                              ref: "vaptchaForm",
                              attrs: {
                                model: _vm.vaptcha,
                                "label-width": 110,
                                "label-position": "left",
                                rules: _vm.vaptchaValidate,
                              },
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "VID", prop: "vid" } },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入验证单元VID",
                                    },
                                    model: {
                                      value: _vm.vaptcha.vid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.vaptcha, "vid", $$v)
                                      },
                                      expression: "vaptcha.vid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "secretKey",
                                    prop: "secretKey",
                                  },
                                },
                                [
                                  _c(
                                    "Tooltip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        content: "无法编辑时请先点击查看图标",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        staticClass: "input-see",
                                        staticStyle: { width: "380px" },
                                        attrs: {
                                          type: "text",
                                          readonly: !_vm.changedVaptchaSK,
                                          icon: "ios-eye",
                                          placeholder: "请输入验证单元Key",
                                        },
                                        on: {
                                          "on-click": function ($event) {
                                            return _vm.seeSecret(
                                              "VAPTCHA_SETTING"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.vaptcha.secretKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.vaptcha,
                                              "secretKey",
                                              $$v
                                            )
                                          },
                                          expression: "vaptcha.secretKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.saveEditVaptcha },
                                    },
                                    [_vm._v("保存更改 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.loading
                            ? _c("Spin", { attrs: { fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "stopWord",
                          expression: "currName == 'stopWord'",
                        },
                      ],
                    },
                    [_c("stop-word")],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "notice",
                          expression: "currName == 'notice'",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "setting-title" },
                        [
                          _vm._v(" 系统公告配置 "),
                          _c(
                            "Tooltip",
                            {
                              attrs: {
                                content: "访问系统公告，右上角弹出",
                                placement: "right",
                              },
                            },
                            [
                              _c("Icon", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  type: "md-help-circle",
                                  size: "20",
                                  color: "#c5c5c5",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "Form",
                            {
                              ref: "noticeForm",
                              attrs: {
                                model: _vm.notice,
                                "label-width": 110,
                                "label-position": "left",
                              },
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "公告开关", prop: "open" } },
                                [
                                  _c(
                                    "i-switch",
                                    {
                                      model: {
                                        value: _vm.notice.open,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.notice, "open", $$v)
                                        },
                                        expression: "notice.open",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "open" },
                                          slot: "open",
                                        },
                                        [_vm._v("开")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "close" },
                                          slot: "close",
                                        },
                                        [_vm._v("关")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "展示时长",
                                    prop: "duration",
                                  },
                                },
                                [
                                  _c(
                                    "Tooltip",
                                    {
                                      attrs: {
                                        trigger: "hover",
                                        placement: "right",
                                        content: "设为0不自动关闭",
                                      },
                                    },
                                    [
                                      _c("InputNumber", {
                                        attrs: { max: 1000, min: 0 },
                                        model: {
                                          value: _vm.notice.duration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notice,
                                              "duration",
                                              $$v
                                            )
                                          },
                                          expression: "notice.duration",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                        },
                                        [_vm._v("秒")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "展示页面",
                                    prop: "position",
                                  },
                                },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "380px" },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.notice.position,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.notice, "position", $$v)
                                        },
                                        expression: "notice.position",
                                      },
                                    },
                                    [
                                      _c(
                                        "Option",
                                        { attrs: { value: "LOGIN" } },
                                        [_vm._v("登录页（访问系统时）")]
                                      ),
                                      _c(
                                        "Option",
                                        { attrs: { value: "HOME" } },
                                        [_vm._v("首页（登录系统后）")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                { attrs: { label: "公告标题", prop: "title" } },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    model: {
                                      value: _vm.notice.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.notice, "title", $$v)
                                      },
                                      expression: "notice.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: { label: "公告内容", prop: "content" },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.notice.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.notice, "content", $$v)
                                      },
                                      expression: "notice.content",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.saveEditNotice },
                                    },
                                    [_vm._v("保存更改 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.loading
                            ? _c("Spin", { attrs: { fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currName == "other",
                          expression: "currName == 'other'",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "setting-title" }, [
                        _vm._v("其他配置"),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "Form",
                            {
                              ref: "otherForm",
                              attrs: {
                                model: _vm.other,
                                "label-width": 110,
                                "label-position": "left",
                                rules: _vm.otherValidate,
                              },
                            },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "应用部署域名",
                                    prop: "domain",
                                  },
                                },
                                [
                                  _c(
                                    "Tooltip",
                                    {
                                      attrs: {
                                        placement: "right",
                                        content: "拼接部分资源访问链接使用",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        staticStyle: { width: "380px" },
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "请输入应用部署域名前缀，如http://xboot.exrick.cn",
                                        },
                                        model: {
                                          value: _vm.other.domain,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.other, "domain", $$v)
                                          },
                                          expression: "other.domain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "单点登录域名",
                                    prop: "ssoDomain",
                                  },
                                },
                                [
                                  _c(
                                    "Tooltip",
                                    {
                                      attrs: {
                                        "max-width": "250",
                                        transfer: "",
                                        placement: "right",
                                        content:
                                          "该域名及子域名下将可获得单点登录所需数据",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        staticStyle: { width: "380px" },
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "请输入应用部署一级域名，如exrick.cn",
                                        },
                                        model: {
                                          value: _vm.other.ssoDomain,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.other,
                                              "ssoDomain",
                                              $$v
                                            )
                                          },
                                          expression: "other.ssoDomain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "IP黑名单",
                                    prop: "blacklist",
                                  },
                                },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "380px" },
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder: "多个以回车分隔",
                                    },
                                    model: {
                                      value: _vm.other.blacklist,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.other, "blacklist", $$v)
                                      },
                                      expression: "other.blacklist",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.saveEditOther },
                                    },
                                    [_vm._v("保存更改 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.loading
                            ? _c("Spin", { attrs: { fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }